import React, { useState } from 'react'
import { useForm, Control } from 'react-hook-form'

import { Button, Space, TextField } from '@probatix/common/components/atoms'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'
import { CustomModal } from '@probatix/common/components/molecules'

import { Trans } from '@probatix/common/translations'

import { EnterVoucherForm } from './EnterVoucher.container'

type EnterVoucherViewProps = ModalBasicProps & {
  defaultValues: EnterVoucherForm
  isSubmitting: boolean
  loading: boolean
  onSubmit: (values: EnterVoucherForm, setFieldErr: Function) => void
}

const EnterVoucherView: React.FC<EnterVoucherViewProps> = ({
  defaultValues,
  isSubmitting,
  loading,
  onHideModal,
  onSubmit,
}) => {
  const [open, setOpen] = useState(true)
  const { control, handleSubmit, register, setError }: {
    control: Control<EnterVoucherForm>
    handleSubmit: any
    register: any
    setError: any
  } = useForm<EnterVoucherForm>({ defaultValues })

  const closeModal = () => {
    setOpen(false)
    onHideModal()
  }

  const handleOnSubmit = (values: EnterVoucherForm) => {
    onSubmit(values, setError)
  }

  const handleUserKeyPress = (e, submit) => {
    if ('Enter' === e.key && !e.shiftKey) {
      submit()
      e.preventDefault()
    }
  }

  const handleFocus = (event) => {
    event.target.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const voucherForm = (<form
    autoComplete="off"
    name="voucher"
    onKeyDown={(e) => handleUserKeyPress(e, handleSubmit(onSubmit))}
    onSubmit={handleSubmit(handleOnSubmit)}
  >
    <TextField
      autoComplete="off"
      control={control}
      errorMargin="10px 0"
      label={(
        <Trans k="modals:EnterVoucher:enterVoucher" />
      )}
      separator=":"
      textTransform="uppercase"
      onFocus={handleFocus}
      {...register('code', { required: true })}
    />
    <Space space="10px" />
    <Button
      $zIndex={300}
      dataQa="saveButton"
      label={<Trans k="label:applyVoucher" />}
      loading={isSubmitting || loading}
      showLoading={loading}
      full
      submit
    />
  </form>)

  return (
    <CustomModal
      $isActiveModal={open}
      hideCrossButton={false}
      isOpen={open}
      title={<Trans k="modals:EnterVoucher:title" />}
      scrollTop
      showTitleOnDialog
      onClose={closeModal}
    >
      {voucherForm}
    </CustomModal>
  )
}

export default EnterVoucherView
