 
import React from 'react'
import { Link } from 'react-router-dom'

import { ButtonProps } from './Button'

export const getComponent = ({ children, target, to, ...rest }: ButtonProps & { children: React.ReactNode }) => {
  if (to && target) {
    // @ts-ignore:next-line
    delete rest?.hasIcon

    return (
      <a
        href={to}
        target={target}
        {...rest}
      >
        {children}
      </a>
    )
  }

  if (to) {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    )
  }

  // @ts-ignore:next-line
  delete rest?.hasIcon

  return (
    <button {...rest}>
      {children}
    </button>
  )
}