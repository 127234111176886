import styled from 'styled-components'
import TextField from '@mui/material/TextField'

import { COLORS } from '@probatix/common/constants/colors'

interface IStyledTextField {
  $hasError?: boolean,
  checkout?: boolean,
  error?: boolean,
  hasError?: boolean,
  height: string
}

export const StyledTextField = styled(TextField) <IStyledTextField>`
  height: ${(props) => (props.height && props.height)};
  padding: 2.125rem 1.375rem 0.8rem 0.94rem;

  .MuiInputBase-root {
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.CADET_BLUE};
    font-size: 0.8rem;;
    margin-bottom: 0.5rem !important;
    
    border-radius: 0.3125rem;
    ${({ checkout }) => checkout && `
      height: 4rem;
      border-radius: 1.375rem;
      margin-bottom: 0 !important;
    `}
    
    outline: none;

    ${({ $hasError }) => $hasError
    && `
      border-color: ${COLORS.ERROR};
    `}

    ${(props) => (props.error
    ? `border: 1px solid  ${COLORS.ERROR} !important;`
    : ` border: 1px solid ${COLORS.CADET_BLUE}`)};

    &:before, &:after {
      border-bottom: 0;
      transition: none;
    }

    &.MuiInputBase-adornedEnd {
      padding-right: 15px;

      ${(props) => (props.hasError
    ? `border: 1px solid  ${COLORS.ERROR};`
    : ` border: 1px solid ${COLORS.CADET_BLUE}`)};
    }
  }

  fieldset {
    border: none;
  }

  .MuiFilledInput-input {
    font-size: 0.8rem;
    position: relative;
    z-index: 10;

    &:hover {
      background: transparent;
      outline: none;
      border: 1px solid ${COLORS.CADET_BLUE};
    }

    :-webkit-autofill {
      border-radius: 25px;
      margin-right: -54px;
    }
  }

  .MuiOutlinedInput-input {
    padding: 23px 1.375rem 5px 0.94rem;
  }

  .MuiOutlinedInput-input::focus-visible {
    padding: 23px 1.375rem 5px 0.94rem;
  }

  .MuiFormLabel-root {
    color: ${COLORS.CADET_BLUE} !important;
    font-size: 0.8rem;
    margin-left: 0.15rem;

    ${({ checkout }) => checkout && `
      top: 6px;
    `}

    &.MuiFormLabel-filled,
    &.Mui-focused {
      color: ${COLORS.CADET_BLUE};
      font-size: 0.8rem;
      top: 17px;
    }
  }

  .MuiInputAdornment-filled {
    z-index: 2000;
  }

  .MuiAutocomplete-input {
    padding: 20px 13px 8px !important;
  }
`

export const StyledImageContainer = styled.div`
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`
