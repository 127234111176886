import React from 'react'
import { useSmallScreens } from '@probatix/common/hooks'
import { Button, CustomDialog, CustomDrawer, Space, Typography } from '@probatix/common/components/atoms'
import { COLORS } from '@probatix/common/constants/colors'
import { TITLE_H2_SIZE } from '@probatix/common/constants/design'

import { StyledHeader } from './CustomModal.styled'

const CustomModal = React.forwardRef(({ children,
  hideCrossButton,
  isOpen,
  key,
  onClose,
  onOpen,
  scrollTop,
  showTitleOnDialog,
  title,
}: any, forwardedRef) => {
  const [$isSmallScreen] = useSmallScreens()

  const handleToggle = (open) => {
    if (open) {
      onOpen()
    } else {
      onClose()
    }
  }

  const dialog = () => (
    <CustomDialog
      hideCrossButton={hideCrossButton}
      key={key}
      open={isOpen}
      showTitleOnDialog={showTitleOnDialog}
      title={title}
      onToggleChange={handleToggle}
    >
      {children}
    </CustomDialog>
  )

  const drawer = () => (
    <CustomDrawer
      isOpen={isOpen}
      key={key}
      ref={forwardedRef}
      scrollTop={scrollTop}
      onToggle={handleToggle}
    >
      <StyledHeader>
        <Typography
          $lineHeight="24px"
          fontSize={TITLE_H2_SIZE}
          fontWeight={600}
        >
          {title}
        </Typography>
        <Button
          icon="close"
          iconColor={COLORS.BLACK}
          iconSize={24}
          label=""
          padding="0"
          textColor={COLORS.BLACK}
          version="transparent"
          onClick={onClose}
        />
      </StyledHeader>
      <Space space="15px" />
      {children}
      <Space space="15px" />
    </CustomDrawer>
  )

  if (!isOpen) {
    return <span />
  }

  return ($isSmallScreen ? drawer() : dialog())
})

export default CustomModal
