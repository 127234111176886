import React, { useState } from 'react'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'
import { CustomModal } from '@probatix/common/components/molecules'

type EnterVoucherViewProps = ModalBasicProps & {
  defaultValues: any
  loading: boolean,
  onHideModal: any,
}

const EnterVoucherView: React.FC<EnterVoucherViewProps> = ({
  defaultValues,
  onHideModal,
}) => {
  const [open, setOpen] = useState(true)

  const closeModal = () => {
    setOpen(false)
    onHideModal()
  }

  return (
    <CustomModal
      $isActiveModal={open}
      hideCrossButton={false}
      isOpen={open}
      scrollTop={defaultValues.scrollTop}
      title={defaultValues.title}
      showTitleOnDialog
      onClose={closeModal}
    >
      {defaultValues.element}
    </CustomModal>
  )
}

export default EnterVoucherView
