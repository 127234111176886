import styled from 'styled-components'

interface IStyledRow {
  columns?: number,
  position?: string
}

export const StyledRow = styled.div<IStyledRow>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, minmax(100px, 1fr) )`};
  position: ${(props) => `${props.position}`};
`

