import styled from 'styled-components'
import { alpha } from '@probatix/common/utils/color'
import { COLORS } from '@probatix/common/constants/colors'
import { MENU_HEIGHT, MOBILE_WIDTH, DEFAULT_MOBILE_MAX_WIDTH } from '@probatix/common/constants/design'

interface StyledLanguageItemProps {
  $active?: boolean
}

export const StyledMainHeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background: linear-gradient( 0deg, 
    ${alpha(COLORS.PALE_BLUE, 0.63)}, 
    ${alpha(COLORS.PALE_BLUE, 0.63)}),
              linear-gradient(0deg, 
                ${alpha(COLORS.PALE_BLUE, 0.04)}, 
                ${alpha(COLORS.PALE_BLUE, 0.04)}
                );
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  padding-left: 5vw;
  padding-right: 5vw;
  margin: auto;
  z-index: 200;

  @media screen  and (max-device-width: 480px) and (orientation: portrait) { 
      padding: 0px;
  }
`

export const StyledMainHeaderContainer = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr auto auto;
  justify-content: space-between;
  align-items: center;
  
  @media${DEFAULT_MOBILE_MAX_WIDTH} {
    grid-template-columns: auto auto;
  }
`

export const StyledSignInButton = styled.div`
  background: rgba(209, 209, 209, 0.72);
  border-radius: 39px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 12px 12px 12px 20px;
  grid-column-gap: 12px;
  cursor: pointer;
  
  @media(max-width: ${MOBILE_WIDTH}px) {
    background: transparent;
    padding: 12px 0 12px 12px;
  }
`

export const StyledLink = styled.a`
  color: ${COLORS.ECLIPSE};
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  img {
    margin: 0;
  }

  &:hover {
    color: ${COLORS.BLACK};
  }

`

export const StyledSignInContainer = styled.div`
  width: 410px;
  padding: 10px;
  
  @media(max-width: 500px) {
    width: calc(100vw - 50px);
    padding: 40px 25px;
  }
`

export const StyledFormWrapper = styled.div`
  display: grid;
  flex-direction: column;
  grid-row-gap: 5px;
  padding: 15px 40px;

  @media(max-width: 500px) {
    padding: 0;
  }
  
  @media${DEFAULT_MOBILE_MAX_WIDTH} {
    padding: 5px 10px;
  }
`

export const StyledLanguageSwitchWrapper = styled.div`
  display: flex;
  font-size: 0.875rem;
  padding-right: 25px;
  
  @media${DEFAULT_MOBILE_MAX_WIDTH} {
    display: flex;
    justify-content: center;
    padding: 20px;
    text-align: center;
    font-size: 18px;
  }
`

export const StyledSeparator = styled.div`
  margin: 0 5px;
`

export const StyledLanguageItem = styled.a<StyledLanguageItemProps>`
  color: ${(props) => ((props.$active)
    ? COLORS.BLACK
    : COLORS.CADET_BLUE)};`

export const StyledLogoutLanguageSwitchWrapper = styled.div`
  padding: 20px 15px 15px;
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  
  & > div {
    padding: 0;
  }
  
  div {
    font-size: 18px;
  }
  
  &:before {
    content: '';
    border-top: 1px solid #000;
    width: 40px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`
export const StyledHR = styled.hr`
  background-color: ${COLORS.HR_DARK_GRAY};
  margin: 0px 0px 0px 0px;
  height: 1px;
  border: none;
`
