/* eslint-disable */
import { Property } from 'csstype'

import styled from 'styled-components'

import React from 'react'

import { COLORS } from '@probatix/common/constants/colors'

import { GridItemProps } from './Grid.model'

interface StyledGridContainerProps {
  alignItems?: Property.AlignItems
  alignSelf?: Property.AlignSelf
  gap?: Property.Gap
  gridTemplateColumns?: Property.GridTemplateColumns
  justifyContent?: Property.JustifyContent
  margin?: string
  maxHeight?: string,
  minHeight?: string,
  padding?: string
}

export const StyledGridContainer = styled(({
  alignItems,
  alignSelf,
  gap,
  gridTemplateColumns,
  justifyContent,
  margin,
  maxHeight,
  minHeight,
  padding,
  ...rest
}) => (
  <div {...rest} />
)) <StyledGridContainerProps>`
  
  display: grid;
  
  ${({ maxHeight }) => maxHeight && `
    max-height: ${maxHeight};
  `}

  ${({ minHeight }) => minHeight && `
    min-height: ${minHeight};
  `}
  
  ${({ gap }) => gap && `
    grid-gap: ${gap};
  `}

  ${({ alignItems }) => alignItems && `
    align-items: ${alignItems};
  `}

  ${({ alignSelf }) => alignSelf && `
    align-self: ${alignSelf};
  `}

  ${({ gridTemplateColumns }) => gridTemplateColumns && `
    grid-template-columns: ${gridTemplateColumns};
  `}

  ${({ justifyContent }) => justifyContent && `
    justify-content: ${justifyContent};
  `}  
  
  ${({ padding }) => padding && `
    padding: ${padding};
  `}  
  
  ${({ margin }) => margin && `
    margin: ${margin};
  `}
`
export const StyledGridItem = styled(({ alignItems, borderLeft, justifyContent, margin, minWidth, padding, ...rest }) => (
  <div {...rest} />
)) <GridItemProps>`
  display: grid;
    
  ${({ alignItems }) => alignItems && `
    align-items: ${alignItems};
  `}

  ${({ borderLeft }) => borderLeft && `
    border-left: 1px solid ${COLORS.CADET_BLUE};
  `}

  ${({ justifyContent }) => justifyContent && `
    justify-content: ${justifyContent};
  `}  
  
  ${({ padding }) => padding && `
    padding: ${padding};
  `}  
  
  ${({ margin }) => margin && `
    margin: ${margin};
  `}

  ${({ minWidth }) => minWidth && `
    min-width: ${minWidth};
  `}
`
