import React from 'react'
import { useTranslation } from 'react-i18next'

import { HtmlParser } from '@probatix/common/components/atoms'
import { CustomModal } from '@probatix/common/components/molecules'
import { Trans } from '@probatix/common/translations'
import { GuestBookingProduct } from '@probatix/common/services/RTKServices/index.model'

import {
  StyledBiomarkerCard,
  StyledBiomarkerDescription,
  StyledBiomarkerName,
  StyledContent,
} from './BiomarkersModal.styled'

const BiomarkersModal = ({
  biomarkers,
  noLine,
  onClose,
  open,
  productName,
}: {
  biomarkers: string[] | object[];
  noLine?: boolean,
  onClose?: any;
  open: boolean,
  productName: GuestBookingProduct['name'];
}) => {
  const { t } = useTranslation()

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const body = () => (
    <StyledContent>
      {Object.entries(biomarkers).map(([, biomarker]) => {
        const bio = biomarker?.loincNum ?? biomarker

        return (
          <StyledBiomarkerCard key={biomarker} noLine={noLine}>
            <StyledBiomarkerName>
              <Trans k={`global:biomarker:${bio}:probatixDisplayName`} />
            </StyledBiomarkerName>
            <StyledBiomarkerDescription>
              <HtmlParser description={t(`global:biomarker:${bio}:description`)} />
            </StyledBiomarkerDescription>
          </StyledBiomarkerCard>
        )
      })}
    </StyledContent>
  )

  return (
    <CustomModal
      isOpen={open}
      title={productName || "test"}
      onClose={handleOnClose}
    >
      {body()}
    </CustomModal>
  )
}

export default BiomarkersModal
