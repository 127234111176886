import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { formatDateLocale } from '@probatix/common/utils/date'
import { convertToDate } from '@probatix/common/hooks/useDateFormat'
import { deDE, enUS } from '@mui/x-date-pickers/locales'

import Image from '@probatix/common/components/atoms/Image'

import i18n, {
  LOCALES as supportedLocales,
} from '@probatix/common/translations'
import useSmallScreens from '@probatix/common/hooks/useSmallScreens'

import { INPUT_HEIGHT_DESKTOP, INPUT_HEIGHT_MOBILE } from '@probatix/common/constants/design'

import calendarSVG from '@probatix/common/assets/icons/calendar.svg'

import { StyledImageContainer, StyledTextField } from './CustomDatePicker.styled'
import { generateAssetUrl } from '../TextField/TextField.helpers'

const defaultFormat = 'yyyy-MM-dd'
const germanLocale = deDE.components.MuiLocalizationProvider.defaultProps.localeText
const englishLocale = enUS.components.MuiLocalizationProvider.defaultProps.localeText

type CustomDatePickerProps = {
  checkout?: boolean;
  disableFuture?: boolean;
  hasError?: boolean;
  initialDay?: string | Date;
  label: React.ReactNode;
  locale: string;
  name: string;
  onChange: Function;
}

export const sanitizeDate = (initialDay?: string | Date) => {
  if (initialDay) {
    if (initialDay instanceof Date) {
      return initialDay
    }
    if ('string' === typeof initialDay) {
      return convertToDate(
        formatDateLocale(new Date(initialDay), defaultFormat),
      )
    }
  }

  return null
}

const CustomDatePicker: React.FC<React.PropsWithChildren<CustomDatePickerProps>> = ({
  checkout,
  initialDay,
  locale = i18n.language,
  disableFuture = true,
  label,
  hasError,
  name,
  onChange,
}) => {

  const [$isSmallScreen] = useSmallScreens()
  const height = $isSmallScreen ? INPUT_HEIGHT_MOBILE : INPUT_HEIGHT_DESKTOP
  const [value, setValue] = React.useState<Date | null>(
    sanitizeDate(initialDay),
  )

  const handleOnChange = (newValue) => {
    setValue(newValue)
    onChange(newValue)
  }

  const calendarIcon = (props) => (
    <StyledImageContainer data-qa="calendar-icon-container">
      <Image
        data-qa="calendar-icon"
        height={18}
        src={generateAssetUrl(calendarSVG)}
        width={18}
        {...props}
      />
    </StyledImageContainer>
  )

  const customTextField = React.useCallback(
    (props) => <StyledTextField checkout={checkout} {...props} />,
    [checkout],
  )

  return (
    <LocalizationProvider
      adapterLocale={supportedLocales[locale]}
      dateAdapter={AdapterDateFns}
      localeText={'de' === locale ? germanLocale : englishLocale}
    >
      <DatePicker
        disableFuture={disableFuture}
        format="dd.MM.yyyy"
        label={label}
        openTo="year"
        slotProps={{
          textField: {
            error: hasError,
            fullWidth: true,
            name,
            sx: {
              borderRadius: checkout ? '1.375rem' : '0.3125rem',
              height: checkout ? '4rem' : height,
            },
          },
        }}
        slots={{
          openPickerIcon: calendarIcon,
          textField: customTextField,
        }}
        value={value}
        views={['year', 'month', 'day']}
        onChange={handleOnChange}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePicker
