import styled from 'styled-components'

import { Property } from 'csstype'

import { COLORS } from '@probatix/common/constants/colors'

export const Z_INDEX_POPOVER = 100

interface StyledBoxProps {
  margin?: Property.Margin,
  zIndex?: number
}

export const StyledBox = styled.div<StyledBoxProps>`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 
    0px 6px 16px rgba(0, 0, 0, 0.08), 
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  background: ${COLORS.WHITE};
  min-height: 35px;
  
  ${({ zIndex }) => `
    z-index: ${zIndex || Z_INDEX_POPOVER};
  `}
  
  ${({ margin }) => margin && `
    margin: ${margin};
  `}
`

interface StyledButtonProps {
  buttonFull?: boolean
}

export const StyledButton = styled.div<StyledButtonProps>`
  ${({ buttonFull }) => buttonFull && `
    @media(max-width: 780px) {
      width: 100%;
    } 
  `}
`
