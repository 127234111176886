import styled from 'styled-components'
import { Badge } from 'react-bootstrap'

import { BORDER_RADIUS } from '@probatix/myprobatix/src/constants'

interface IStyledBadge {
  $bg?: string;
  $textColor?: string;
}

export const StyledBadge = styled(Badge) <IStyledBadge>`
  margin-left: -5px;
  text-transform: capitalize;
  font-size: 0.82225rem;
  padding: 5px 2px;
  border-radius: ${BORDER_RADIUS};
  
  ${(props) => props.$bg && `
    background: ${props.$bg}
  `};

  ${(props) => props.$textColor && `
    color: ${props.$textColor}
`};
  font-weight: 700;
  width: fit-content;
`
