import { combineReducers } from 'redux'

import { AuthRootState } from '@probatix/common/services/toolkit/auth/auth.model'
import { TranslationsRootState } from '@probatix/common/services/toolkit/translations/translationsService.model'

import { modalServiceReducers, ModalRootState } from '@probatix/common/services/toolkit/modal'
import { authServiceReducers } from '@probatix/common/services/toolkit/auth'
import { translationsServiceReducers } from '@probatix/common/services/toolkit/translations'

import { locationsApi } from '@probatix/common/services/RTKServices/locations'

import { AppServiceRootState } from 'services/app/appService.model'
import { appServiceReducers } from 'services/app'

export interface RootState extends AuthRootState,
  AppServiceRootState,
  ModalRootState,
  TranslationsRootState { }

const reducers = combineReducers({
  app: appServiceReducers,
  auth: authServiceReducers,
  modal: modalServiceReducers,
  translations: translationsServiceReducers,
  [locationsApi.reducerPath]: locationsApi.reducer,
})

export default reducers

