import styled from 'styled-components'

import { IImageProps } from './Image'

export const ImageStyled = styled.img<IImageProps>`  
  display: block;
  
  ${({ $objectFit }) => $objectFit && `
    object-fit: ${$objectFit};
  `}

  ${({ $objectPosition }) => $objectPosition && `
    object-position: ${$objectPosition};
  `}
  
  ${({ margin }) => margin && `
    margin: ${margin};
  `}
  
  ${({ full }) => full && `
    width: 100%;
  `}
`
