import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { isLocaleSupported } from 'translations'

import MainHeader from './components/MainHeader'

const ShellView: React.FC = () => {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { locale } = useParams()

  useEffect(() => {
    // TODO: v6 does not support regex https://reactrouter.com/en/main/upgrading/v5#note-on-route-path-patterns
    const urlLocale = locale || i18n.language
    if (!isLocaleSupported(urlLocale)) {
      return navigate(`/${i18n.language}/pick-slot`)
    }
  }, [])

  return (
    <React.Fragment>
      <div data-qa="shell" data-testid="shell">
        <MainHeader />
        <Outlet />
      </div>
    </React.Fragment>
  )
}

export default ShellView
