import { PayloadAction } from '@reduxjs/toolkit'

import { ActionParams } from '@probatix/common/utils/service'

import { testsServiceActions } from '@probatix/common/services/toolkit/tests'

type Action = PayloadAction<ActionParams<object>>

const testsFormPersistMiddleware = () => (next) => async (action: Action) => {
  const { type } = action

  if (!type.includes('testForm') || testsServiceActions.loadData.match(action)) {
    return next(action)
  }

  const results = next(action)

  return results
}

export default testsFormPersistMiddleware
