import React from 'react'

import { COLORS } from '@probatix/common/constants/colors'
import { Trans } from '@probatix/common/translations'
import { Nullable } from '@probatix/common/models/global'
import Typography from '@probatix/common/components/atoms/Typography'

import { StyledErrorText } from './ErrorText.styled'

interface Params {
  fontSize?: string
  fontWeight?: number
  margin?: string
  msg: Nullable<string>
  params?: {}
}

const ErrorText: React.FC<Params> = ({
  fontSize = '13px',
  fontWeight,
  margin = '5px 0 7px',
  msg,
  params,
}) => {
  if (!msg) {
    return null
  }

  return (
    <StyledErrorText>
      <Typography color={COLORS.ERROR} fontSize={fontSize} fontWeight={fontWeight} margin={margin}>
        <Trans k={msg} params={params} />
      </Typography>
    </StyledErrorText>
  )
}

export default ErrorText
