import { alpha } from '@probatix/common/utils/color'
import styled, { keyframes } from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'

import { COLORS } from '@probatix/common/constants/colors'

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(.96);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

export const StyledDialogOverlay = styled(Dialog.Overlay)`
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: ${alpha(COLORS.BLACK, 0.6)};
    inset: 0;
    position: fixed;
    z-index: 200;
`

export const StyledDialogCrossButton = styled.div`
  float: right;
  position: relative;
`

export const StyledBodyContainer = styled.div`
  position: relative;
`

export const StyledDialogContent = styled(Dialog.Content)`
  &:focus {
    outline: none;
  }

  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  left: 50%;
  max-height: 85vh;
  max-width: 450px;
  padding: 25px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  overflow: scroll;
  z-index: 201;
`

export const Flex = styled.div`
  display: 'flex';
`

export const Button = styled.button`
  align-items: 'center';
  all: 'unset';
  border-radius: 4;
  default-variants: {
    variant: 'violet',
  };
  
  display: 'inline-flex';

  font-size: 15;
  font-weight: 500;
  height: 35;
  justify-content: 'center';
  line-height: 1;
  padding: '0 15px';


  variants {
    variant {
      green {

        &:focus { box-shadow: 0 0 0 2px green };
        &:hover { background-color: "green" };
        background-color: "green";
        color: "green",
      };

      violet {
        &:focus { box-shadow: 0 0 0 2px black }
        &:hover { background-color: "blue" }
        background-color: 'white';
        box-shadow: 0 2px 10px black;
        color: "pink",
      };
    };
  };
`

export const IconButton = styled.button`
  &:focus{
    box-shadow: 0 0 0 2px pink;
  }

  &:hover{
    background-color: pink;
  }

  align-items: 'center';
  all: 'unset';
  border-radius: '100%';
  color: "pink";
  display: 'inline-flex';
  font-family: 'inherit';
  height: 25;
  justify-content: 'center';
  position: 'absolute';
  right: 10;
  top: 10;
  width: 25;
`

export const Fieldset = styled.fieldset`
  align-items: 'center';
  all: 'unset';
  display: 'flex';
  gap: 20;
  margin-bottom: 15;
`

export const Label = styled.label`
  color: "pink";
  font-size: 15;
  text-align: 'right';
  width: 90;
`

export const Input = styled.input`
  &:focus {
    box-shadow: 0 0 0 2px red;
  }
  align-items: 'center';
  all: 'unset';
  border-radius: 4;
  box-shadow: 0 0 0 1px black;
  color: "pink";
  display: 'inline-flex';
  flex: '1';
  font-size: 15;
  height: 35;
  justifyContent: 'center';
  line-height: 1;
  padding: '0 10px';
  width: '100%';
`
