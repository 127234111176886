import { alpha } from '@probatix/common/utils/color'
import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'

import { typeByObject } from '@probatix/common/utils/typescript'
import { BORDER_RADIUS } from '@probatix/myprobatix/src/constants'

import { getComponent } from './Button.helpers'

// @ts-nocheck
export const BUTTON_VERSION = {
  BLUE_LIGHT: 'blue_light',
  BLUE_LIGHT_BORDERED: 'blue_light_bordered',
  BLUE_LIGHT_DISABLED: 'blue_light_disabled',
  BORDERED: 'bordered',
  DISABLED: 'disabled',
  FULL_TRANSPARENT: 'full_transparent',
  GRAY: 'gray',
  GRAY_VERIFIED: 'gray_verified',
  NEUTRAL_BORDERED: 'neutral_bordered',
  PAYPAL: 'paypal',
  RED: 'red',
  RED_BORDERED: 'red_bordered',
  TRANSPARENT: 'transparent',
  TRANSPARENT_BLUE: 'transparent_blue',
  VOLCANO: 'volcano',
  YELLOW: 'yellow',
}

export const BUTTON_SIZE = {
  SMALL: 'small',
  STANDARD: 'standard',
}

interface StyledButtonProps {
  $align?: string,
  $bgColor?: string,
  $border?: string,
  $bottom?: string,
  $boxShadow?: string,
  $capitalize?: boolean,
  $decoration?: string,
  $fontSize?: string,
  $fontWeight?: number,
  $full?: boolean,
  $gridGap?: string,
  $hasIcon?: boolean,
  $height?: string,
  $hoverBGColor?: string,
  $hoverColor?: string,
  $iconColor?: string,
  $isLoading?: boolean,
  $isSmallScreen: boolean,
  $left?: string,
  $margin?: string,
  $mobilePadding?: string,
  $padding?: string,
  $position?: string,
  $radius?: string,
  $right?: string
  $size?: typeByObject<typeof BUTTON_SIZE>
  $textColor?: string
  $version?: typeByObject<typeof BUTTON_VERSION>
  $width?: string
  $zIndex?: number,
  disabled?: boolean,
  type?: 'submit' | 'button'
}

export const StyledButton = styled(getComponent) <StyledButtonProps>`
  background: ${COLORS.BLUE_LIGHT};
  color: ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS};
  font-size: 0.875px;
  padding: 17px 7px;
  cursor: pointer;
  transition: .2s;
  border: 2px solid ${COLORS.BLUE_LIGHT};
  text-align: center;
  display: inline-block;
  -webkit-appearance: none !important;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  
  &:hover {
    color: ${COLORS.WHITE};
    background: ${COLORS.BLUE};
  }

  ${({ $isSmallScreen }) => ($isSmallScreen
    ? 'font-weight: 400;'
    : 'font-weight: 700;')}

  ${({ $align }) => $align && `
    float: ${$align} !important;
  `}

  ${({ $bottom }) => $bottom && `
    bottom: ${$bottom} !important;
  `}

  ${({ $boxShadow }) => $boxShadow && `
    box-shadow: ${$boxShadow} !important;
  `}

  ${({ $height }) => $height && `
    height: ${$height} !important;
  `}

  ${({ $textColor }) => $textColor && `
    color ${$textColor} !important;
  `}

  ${({ $padding }) => $padding && `
    padding: ${$padding} !important;
  `}

  ${({ $position }) => $position && `
    position: ${$position};
  `}

  ${({ $width }) => $width && `
    width: ${$width} !important;
  `}

  ${({ $margin }) => $margin && `
    margin: ${$margin};
  `}

  ${({ $full }) => $full && `
    width: 100%;
  `}

  ${({ $capitalize }) => !$capitalize && `
    text-transform: uppercase;
  `}

  ${({ $decoration }) => $decoration && `
    text-decoration: ${$decoration};
  `}

  ${({ $fontSize }) => ($fontSize
    ? `font-size: ${$fontSize} !important;`
    : 'font-size: 16px;'
  )}

  ${({ $fontWeight }) => $fontWeight && `
    font-weight: ${$fontWeight};
  `}

  ${({ $left }) => $left && `
    left: ${$left} !important;
  `}

  ${({ $right }) => $right && `
    right: ${$right} !important;
  `}
  
  ${({ $hasIcon }) => $hasIcon && `
    display: grid;
    grid-template-columns: 0.875rem auto;
    align-items: center;
    grid-gap: 15px;
    padding: 15px 32px;
    width: max-content;

    .icons {
      color: ${COLORS.WHITE} !important;
      transition: .2s;
    }
    
    &:hover {
      .icons {
        color: ${COLORS.WHITE} !important;
      }
    }
  `}

  ${({ $gridGap, $hasIcon }) => $hasIcon && $gridGap && ` 
    grid-gap: ${$gridGap} !important;
  `}

  ${({ $hasIcon, $textColor }) => $hasIcon && $textColor && ` 
    color: ${$textColor} !important;
  `}

  ${({ $hasIcon, $padding }) => $hasIcon && $padding && ` 
    padding: ${$padding};
  `}

${({ $version }) => BUTTON_VERSION.DISABLED === $version && `
    background: ${COLORS.DISABLE_GRAY};
    border-color: ${COLORS.DISABLE_GRAY};

    &:hover {
      background: ${COLORS.DISABLE_GRAY};
      border-color: ${COLORS.DISABLE_GRAY};
      color:  ${COLORS.WHITE};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.GRAY === $version && `
    background: ${COLORS.CADET_BLUE};

    &:hover {
      color:  ${COLORS.BLACK};
      background: ${COLORS.CADET_BLUE};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.BLUE_LIGHT === $version && `
    background: ${COLORS.BLUE_LIGHT};
    
    &:focus, &:hover {
      background: ${COLORS.BLUE};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.BLUE_LIGHT_DISABLED === $version && `
    background: ${COLORS.BLUE_LIGHT};
    color: ${COLORS.BLUE_LIGHT_DISABLED};
    
    &:focus, &:hover {
      background: ${COLORS.BLUE};
          color: ${COLORS.BLUE_LIGHT_DISABLED};
    }
  `}
    
  ${({ $version }) => BUTTON_VERSION.BORDERED === $version && `
    background: ${COLORS.WHITE};
    border: 2px solid ${alpha(COLORS.ECLIPSE, 0.3)};
    color: ${COLORS.ECLIPSE};

    &:hover {
      background: ${COLORS.ECLIPSE};
      color: ${COLORS.WHITE};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.FULL_TRANSPARENT === $version && `
    background: transparent;
    border-color: transparent;
    color: ${COLORS.BLACK};
    
    &:hover {
      color: ${COLORS.BLACK} !important;
      background: transparent;
    }

    &:focus {
      color: ${COLORS.BLACK} !important;
      background: transparent;
    }

    &:focus-visible {
      outline: none;
    }
  `}

  ${({ $version }) => BUTTON_VERSION.BLUE_LIGHT_BORDERED === $version && `
    background: ${COLORS.WHITE};
    border: 2px solid ${COLORS.BLUE_LIGHT};
    color: ${COLORS.BLUE_LIGHT};

    &:hover {
      color: ${COLORS.NAVY_BLUE};
      background: ${COLORS.WHITE_HOVER};
      border: 2px solid ${COLORS.NAVY_BLUE};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.NEUTRAL_BORDERED === $version && `
    background: transparent;
    border: 1px solid ${COLORS.NEUTRAL};
    color: ${COLORS.ECLIPSE};

    &:hover {
      color: ${COLORS.ECLIPSE};
      border: 1px solid ${COLORS.ECLIPSE};
      background: transparent;
    }
  `}

  ${({ $version }) => BUTTON_VERSION.GRAY_VERIFIED === $version && `
    background: transparent;
    border: 1px solid ${COLORS.NEUTRAL};
    color: ${COLORS.GRAY_VERIFIED};

    &:hover {
      color: ${COLORS.GRAY_VERIFIED};
      border: 1px solid ${COLORS.GRAY_VERIFIED};
      background: transparent;
    }
  `}

  ${({ $version }) => BUTTON_VERSION.TRANSPARENT === $version && `
    background: transparent;
    color: ${COLORS.BLACK};
    border-width: 0;
    
    &:focus {
      outline: none;
      background: ${alpha(COLORS.BLUE, 0.05)};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.VOLCANO === $version && `
    background: ${COLORS.VOLCANO};
    border-color: ${COLORS.VOLCANO};

    &:focus, &:hover {
      background: ${COLORS.VOLCANO};
      border-color: ${COLORS.VOLCANO};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.YELLOW === $version && `
    background: ${COLORS.YELLOW};
    border-color: ${COLORS.YELLOW};
    
    &:focus, &:hover {
      background: ${COLORS.YELLOW_HOVER};
      border-color: ${COLORS.YELLOW};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.PAYPAL === $version && `
    background: ${COLORS.PAYPAL_YELLOW};
    border-color: ${COLORS.PAYPAL_YELLOW};
    cursor: default;
    
    &:focus, &:hover {
      background: ${COLORS.PAYPAL_YELLOW};
      border-color: ${COLORS.PAYPAL_YELLOW};
      cursor: default;
    }
  `}
    
  ${({ $version }) => BUTTON_VERSION.TRANSPARENT_BLUE === $version && `
    background: transparent;
    color: ${COLORS.BLUE};
    
    &:focus, &:hover {
      color: ${COLORS.WHITE};
      background: ${COLORS.BLUE};
    }
  `}

  ${({ $version }) => BUTTON_VERSION.RED === $version && `
    background: transparent;
    color: ${COLORS.ERROR};
    
    &:focus, &:hover {
      color: ${COLORS.WHITE};
      background: ${COLORS.ERROR};
    }
  `}

${({ $version }) => BUTTON_VERSION.RED_BORDERED === $version && `
    background: ${COLORS.WHITE};
    border: 2px solid ${COLORS.ERROR};
    color: ${COLORS.ERROR};

    &:hover {
      color: ${COLORS.ERROR};
      background: ${COLORS.WHITE_HOVER};
      border: 2px solid ${COLORS.ERROR};
    }
  `}
  
  ${({ $hasIcon, $version }) => $hasIcon
    && (BUTTON_VERSION.TRANSPARENT === $version || BUTTON_VERSION.FULL_TRANSPARENT === $version)
    && `
      background: transparent;
      color: ${COLORS.BLACK};
      
      &:focus, &:hover {
        color: ${COLORS.CADET_BLUE};
        background: transparent;
      }
  `}
  
  ${({ $hasIcon, $version }) => $hasIcon && BUTTON_VERSION.TRANSPARENT === $version && `
    .icons {
      color: ${COLORS.BLACK} !important;
    }
    .icons:hover {
      color: ${COLORS.BLACK} !important;
    }
  `}

    
  ${({ $hasIcon, $iconColor }) => $hasIcon && `
    .icons {
      color: ${$iconColor} !important;
    }
    .icons:hover {
      color: ${$iconColor} !important;
    }
    &:focus, &:hover {
      .icons {
        color: ${$iconColor} !important;
      }
    }
  `}

  ${({ $version, disabled }) => disabled
    && (BUTTON_VERSION.TRANSPARENT !== $version
      && BUTTON_VERSION.FULL_TRANSPARENT !== $version
    )
    && `
      background: ${COLORS.DISABLE_GRAY} !important;
      border: 2px solid ${COLORS.DISABLE_GRAY} !important;
      color: ${COLORS.WHITE} !important;
  `}

  ${({ $isLoading, $padding }) => $isLoading && $padding && ` 
    padding: ${$padding};
  `}

  ${({ $zIndex }) => $zIndex && ` 
    z-index: ${$zIndex};
    position: relative;
  `}

  ${({ $size }) => BUTTON_SIZE.SMALL === $size && `
    padding: 8px 12px;
  `}
 
  ${({ $bgColor }) => $bgColor && `
    background-color: ${$bgColor};
    border-color: ${$bgColor};
  `}

  ${({ $border }) => $border && `
    border: ${$border} !important;
  `}

  ${({ $radius }) => $radius && `
    border-radius: ${$radius};
  `}

  ${({ $hoverBGColor }) => $hoverBGColor && `
    &:hover {
      background:${$hoverBGColor} !important;
      border-color:${$hoverBGColor} !important;
    }
  `}

  ${({ $hoverColor }) => $hoverColor && `
    &:hover {
      color:${$hoverColor} !important;
    }
  `}
 `

export const StyledSpinnerWrapper = styled.div`
  height: 15px;
  
  div {
    margin-left: 0;
  }
`
