/* eslint-disable */
import styled from 'styled-components'

import React from 'react'
import { Link } from 'react-router-dom'

import { COLORS } from '@probatix/common/constants/colors'

export const StyledBreadcrumb = styled.div`
  display: flex;
  margin: 10px 0;
`

export const StyledBreadcrumbItemWrapper = styled.div`
  color: ${COLORS.BREADCRUMB_GREY};

  &:after {
    content: '>';
    margin: 0 6px;
    font-size: 0.6rem;
  }
  
  &:nth-last-of-type(1) {
    &:after {
      content: '';
    }
  }
`

interface StyledBreadcrumbItemProps {
  $isActive?: boolean
}

// @ts-ignore
export const StyledBreadcrumbItem = styled(({ $isActive, ...rest }) => <Link {...rest} />) <StyledBreadcrumbItemProps>`
  color: ${COLORS.BREADCRUMB_GREY};
  font-size: 0.675rem;
  
  &:hover, &:focus {
    color: ${COLORS.BLACK};
  }
  
  ${({ $isActive }) => $isActive && `
    color: ${COLORS.BLACK};
  `}
`
