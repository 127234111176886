import styled from 'styled-components'

import { SpaceProps } from './Space.model'

export const StyledSpace = styled.div<SpaceProps>`
  ${({ margin }) => margin && `
    margin: ${margin};
  `}
  
  ${({ padding }) => padding && `
    padding: ${padding};
  `}
  
  ${({ space }) => space && `
    margin-bottom: ${space};
  `}  
  
  ${({ inline }) => inline && `
    display: inline;
  `}
`
