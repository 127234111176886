import { alpha } from '@probatix/common/utils/color'
import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'

export const StyledWrapper = styled.div`
`

export const StyledFieldContentWithBorder = styled.div`
  display: flex;
  align-items: center;

  &:after {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin: 0 10px;
    content: '|';
    color: ${COLORS.WHITE};
  }
`

export const StyledField = styled.div`
  display: inline-block;
  align-items: center;
  margin-top: 10px;

  a {
    color: ${COLORS.WHITE};

    &:hover, &:focus {
      color: ${COLORS.WHITE};
    }    
  }
  
  &:nth-last-of-type(1) {
    ${StyledFieldContentWithBorder}:after {
      display: none;
    }
  }
`

export const StyledFieldContent = styled.div`
  padding: 10px;
  font-size: 10px;
  font-weight: 600;
  background: ${alpha(COLORS.SPACE_BLUE, 0.2)};
`
