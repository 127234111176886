import { alpha } from '@probatix/common/utils/color'
import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'
import { CHECKOUT_MAX_WIDTH, MOBILE_WIDTH } from '@probatix/common/constants/design'

export const StyledWeekWrapper = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 20px auto 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${COLORS.CADET_BLUE};
`

interface IStyledDayWrapper {
  isPast?: boolean
}

interface IStyledWrapper {
  $myProbatix?: boolean
}

export const StyledDayWrapper = styled.div<IStyledDayWrapper>`
  display: grid;
  grid-row-gap: 5px;
  text-align: center;
  transition: .2s;
  padding: 15px 10px;
  border-radius: 6px;

  @media(max-width: ${MOBILE_WIDTH}px) {
    padding: 15px 0;
  }

  ${({ isPast }) => !isPast && `
    cursor: pointer;

    &:hover {
      background: ${alpha(COLORS.BLUE_LIGHT, 0.06)};
    }
  `}
`

interface StyledDayProps {
  isSelectedDay?: boolean
}

export const StyledDay = styled.div<StyledDayProps>`
  border-radius: 5px;
  text-align: center;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  
  ${({ isSelectedDay }) => isSelectedDay && `
    background: ${COLORS.BLUE_LIGHT};
    color: ${COLORS.WHITE};
  `}
`

interface StyledButtonsWrapperProps {
  amountButtons?: number
}

export const StyledButtonsWrapper = styled.div<StyledButtonsWrapperProps>`
  padding: 10px 0;
  margin: 0 -10px;
  display: grid;
  grid-gap: 5px;
  
  button {
    font-weight: 500;
    
  }
  
  ${({ amountButtons }) => amountButtons && `
    grid-template-columns: repeat(${amountButtons}, auto);
  `}
  
  @media(max-width: 550px) {
    grid-gap: 3px;
    
    & > button {
      padding: 10px;
      font-size: 12px;
    }
  }
`

export const StyledTimeWrapper = styled.div`
  overflow-y: scroll;
  height: 300px;
  margin-top: 10px;
  scroll-behavior: smooth;
  position: relative;
`

export const StyledTimeOptionsWrapper = styled.div`
  margin-bottom: 25px;
`

interface StyledTimeOptionProps {
  disabled?: boolean
  selected?: boolean
}

export const StyledTimeOption = styled.div<StyledTimeOptionProps>`
  border-left: 6px solid transparent;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0 0 0 / 21%);
  cursor: pointer;
  
  &:hover {
    background: ${alpha(COLORS.BLUE_LIGHT, 0.06)};
  }
  
  ${({ disabled }) => disabled && `
    opacity: 0.2;
    cursor: not-allowed
  `}

  ${({ selected }) => selected && `
    border-left: 6px solid ${COLORS.BLUE_LIGHT};
    background: ${alpha(COLORS.BLUE_LIGHT, 0.06)};
  `}
`

export const StyledChevronBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const StyledWrapper = styled.div<IStyledWrapper>`
  position: relative;  

  ${({ $myProbatix }) => $myProbatix && `
      background: ${COLORS.WHITE};
      border-radius: 20px;
      padding: 20px;
      max-width: ${CHECKOUT_MAX_WIDTH};
      margin: auto;
  `}
`

export const StyledDaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  user-select: none;
`
