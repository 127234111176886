import React from 'react'

import { TypographyPropsBasic } from './Typography.model'
import { StyledTypography } from './Typography.styled'

export interface TypographyProps extends TypographyPropsBasic {
  ref?: React.Ref<any>
}

const Typography = React.forwardRef<HTMLDivElement, React.PropsWithChildren<TypographyProps>>(
  ({ children, linkColorInherit, onClick, variant, ...rest }, ref) => (
    <StyledTypography
      as={variant}
      linkColorInherit={linkColorInherit}
      ref={ref}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {children}
    </StyledTypography>

  ))

export default Typography
