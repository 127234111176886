import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppServiceState } from './appService.model'

const initialState = {
  subdomain: null,
} as AppServiceState

export default createSlice({
  initialState,
  name: 'app',
  reducers: {
    subdomain: (state: AppServiceState, action: PayloadAction<string>) => {
      state.subdomain = action.payload
    },
  },
})
