import addDays from 'date-fns/addDays'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import set from 'date-fns/set'
import startOfWeek from 'date-fns/startOfWeek'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'

import { COLORS } from '@probatix/common/constants/colors'

import { NullableString } from '@probatix/common/models/global'
import { AvailabilityTime } from '@probatix/common/services/toolkit/availabilities/availabilitiesService.model'

import { getValidationMessages } from '@probatix/common/utils/form'
import { formatDateLocale } from '@probatix/common/utils/date'

import { getCurrentLocale } from '@probatix/common/translations'
import { Button, ErrorText, Grid, Icon, Spinner, Typography } from '@probatix/common/components/atoms'

import { Trans } from '@probatix/common/translations'

import {
  StyledWeekWrapper,
  StyledDayWrapper,
  StyledDay,
  StyledButtonsWrapper,
  StyledTimeWrapper,
  StyledTimeOptionsWrapper,
  StyledTimeOption,
  StyledChevronBox,
  StyledWrapper,
  StyledDaysWrapper,
} from './AdvancedTimePicker.styled'

interface AdvancedTimePickerProps {
  $myProbatix?: boolean,
  availabilityTimes: AvailabilityTime[] | undefined,
  availableDays: string[],
  control: any,
  isFetching: boolean,
  isFetchingTimes: boolean,
  name: string,
  onChangeDate: (date: any) => void,
  selectedDate: NullableString
  separator?: string
}

interface TimeSection {
  end: number
  start: number
  times?: any[]
  title: string
}

const TIME_SECTIONS: TimeSection[] = [
  {
    end: 8,
    start: 0,
    title: 'components:AdvancedTimePicker:earlyBirds',
  },
  {
    end: 12,
    start: 8,
    title: 'components:AdvancedTimePicker:morning',
  },
  {
    end: 20,
    start: 12,
    title: 'components:AdvancedTimePicker:afternoon',
  },
  {
    end: 24,
    start: 20,
    title: 'components:AdvancedTimePicker:night',
  },
]

const VirtualizeSwipeableViews = virtualize(SwipeableViews)
let firstAvailableSlot: NullableString = null

const AdvancedTimePicker: React.FC<AdvancedTimePickerProps> = ({
  $myProbatix = false,
  availabilityTimes,
  availableDays,
  control,
  isFetching,
  isFetchingTimes,
  name,
  onChangeDate,
  selectedDate,
  separator,
}) => {

  const [activeTimeSection, setActiveTimeSection] = useState<number>(0)
  const [selectedDay, setSelectedDay] = useState<Date>(
    selectedDate ? new Date(selectedDate) : new Date(),
  )
  const [selectedTmpDay, setSelectedTmpDay] = useState<Date>(
    selectedDate ? new Date(selectedDate) : new Date(),
  )
  const timeSectionsRef = useRef<any>([])
  const timeOptionRef = useRef<any>([])

  useEffect(() => {
    const date1 = format(new Date(selectedDay), 'dd/MM/yyyy')
    const date2 = selectedDate ? format(new Date(selectedDate), 'dd/MM/yyyy') : null

    if (selectedDate && date1 !== date2) {
      const date = new Date(selectedDate)

      firstAvailableSlot = null

      setSelectedDay(date)
      setSelectedTmpDay(date)
    }
  }, [selectedDate])

  useEffect(() => {
    if (availabilityTimes?.length && !isFetchingTimes) {
      const firstAvailableTimeIndex = availabilityTimes.findIndex((time) => time.available)

      if (timeOptionRef.current[firstAvailableTimeIndex] && 0 < firstAvailableTimeIndex) {
        timeOptionRef.current[firstAvailableTimeIndex - 1].scrollIntoView()
      }
    }
  }, [isFetchingTimes])

  const handleScroll = ({ target: parentElement }) => {
    const index = timeSectionsRef.current
      .map((element, i) => {
        let offsetFromTop = 0
        timeSectionsRef.current.slice(0, i).forEach((elem) => {
          offsetFromTop += elem?.offsetHeight || 0
        })

        return offsetFromTop < parentElement.scrollTop
      })
      .lastIndexOf(true)

    if (activeTimeSection !== index) {
      setActiveTimeSection(0 <= index ? index : 0)
    }
  }

  const renderDay = (index) => {
    const selectedDayDate = new Date(selectedDay)
    const iterationDay = addDays(startOfWeek(selectedDayDate, { locale: getCurrentLocale() }), index)
    const disabled = isFetching
      || isBefore(iterationDay, addDays(new Date(), -1))
      || !availableDays?.includes(format(iterationDay, 'dd/MM/yyyy'))

    const isSelectedDay = isSameDay(iterationDay, selectedTmpDay)
    let color = disabled ? COLORS.CADET_BLUE : undefined

    if (isSelectedDay) {
      color = COLORS.WHITE
    }

    const day = formatDateLocale(iterationDay, 'dd')

    return (
      <StyledDayWrapper
        data-qa={`time.calendarDay_${day}`}
        isPast={disabled}
        key={format(iterationDay, 'yyyyMMdd')}
        onClick={() => {
          if (!disabled) {
            onChangeDate(iterationDay)
            setSelectedTmpDay(iterationDay)
          }
        }}
      >
        <Typography color={disabled ? COLORS.CADET_BLUE : undefined} fontSize="0.675rem">
          {formatDateLocale(iterationDay, 'EEE')}
        </Typography>
        <StyledDay isSelectedDay={isSelectedDay}>
          <Typography color={color} fontSize="0.675rem" fontWeight={600}>
            {day}
          </Typography>
        </StyledDay>
      </StyledDayWrapper>
    )
  }

  const handleChangeDate = (direction) => setSelectedDay(addDays(selectedDay, direction * 7))

  const scrollToTimeSectionTitle = (index) => {
    setActiveTimeSection(index)
    timeSectionsRef.current[index].scrollIntoView()
  }

  const timesSection = useMemo(() => {
    if (!availabilityTimes?.length) {
      return
    }

    const response = TIME_SECTIONS.map((section) => ({ ...section }))
    availabilityTimes.forEach((time) => {
      response.forEach((section, index) => {
        const startHour = +format(parse(time.start, 'HH:mm', new Date(0)), 'HH')
        const startMinutes = +format(parse(time.start, 'HH:mm', new Date(0)), 'mm')
        const startIsoTime = startHour * 60 * 60 + (startMinutes * 60)

        const endHour = +format(parse(time.end, 'HH:mm', new Date(0)), 'HH')
        const endMinutes = +format(parse(time.end, 'HH:mm', new Date(0)), 'mm')
        const endIsoTime = endHour * 60 * 60 + (endMinutes * 60)

        if (
          section.start * 60 * 60 <= startIsoTime
          && startHour < section.end
          && endHour * 60 * 60 <= section.end * 60 * 60
          && ((24 !== section.end && 0 !== endIsoTime) || (24 === section.end))
        ) {
          if (!response[index].times) {
            response[index].times = []
          }

          // @ts-ignore
          response[index].times.push(time)
        }
      })
    })

    return response.filter(({ times }) => times?.length)
  }, [availabilityTimes])

  const renderTimeSection = (formValue, onChange) => ({ times, title }, timeSectionIndex) => (
    <div
      key={title}
      ref={(element) => {
        timeSectionsRef.current[timeSectionIndex] = element
      }}
    >
      <Typography
        fontSize="0.875rem"
        margin="20px 0 10px"
      >
        <span>
          <Trans k={title} />
        </span>
      </Typography>
      <StyledTimeOptionsWrapper>
        {times.map((time, timeIndex) => {
          const startTime = parse(time.start as any, 'HH:mm', new Date(0))
          const endTime = parse(time.end as any, 'HH:mm', new Date(0))
          let amountElementsFromPreviousSections = 0

          timesSection?.forEach((section, index) => {
            if (index < timeSectionIndex) {
              amountElementsFromPreviousSections += (section.times ? section.times.length : 0)
            }
          })

          const timeTotalIndex = amountElementsFromPreviousSections + timeIndex
          const startTimeFormatted = format(startTime, 'HH:mm')
          let dataQa: string | null = null

          if ((time.available && !firstAvailableSlot) || startTimeFormatted === firstAvailableSlot) {
            firstAvailableSlot = startTimeFormatted
            dataQa = 'time.firstAvailableSlot'
          }

          return (
            <StyledTimeOption
              data-qa={dataQa}
              disabled={!time.available}
              key={`${startTimeFormatted}${format(endTime, 'HH:mm')}`}
              ref={(element) => {
                timeOptionRef.current[timeTotalIndex] = element
              }}
              selected={(
                formValue
                && format(startTime, 'HHmm') === format(formValue, 'HHmm')
                && format(selectedTmpDay, 'yyyyMMdd') === format(formValue, 'yyyyMMdd')
              )}
              onClick={() => {
                if (time.available && !isFetching && !isFetchingTimes) {
                  onChange(
                    set(selectedTmpDay || new Date(), {
                      hours: +format(startTime, 'HH'),
                      minutes: +format(startTime, 'mm'),
                      seconds: 0,
                    }),
                  )
                }
              }}
            >
              <Grid gap="20px" gridTemplateColumns="max-content max-content ">
                <Grid.Item>
                  <Typography $lineHeight='1.25rem' fontSize="0.775rem">
                    {startTimeFormatted}
                  </Typography>
                </Grid.Item>
                <Grid.Item>
                  <Typography color={COLORS.CADET_BLUE} fontSize="12px" variant="span">
                    <Trans k="components:AdvancedTimePicker:until" />
                    {' '}
                    {format(endTime, 'HH:mm')}
                  </Typography>
                </Grid.Item>
              </Grid>
            </StyledTimeOption>
          )
        })}
      </StyledTimeOptionsWrapper>
    </div >
  )

  const slideRenderer = ({ index, key }) => (
    <div key={key}>
      <StyledDaysWrapper>
        {Array.from({ length: 7 }, (_, i) => renderDay(i + (index * 7)))}
      </StyledDaysWrapper>
    </div>
  )

  const renderField = (formField) => {
    const { field, fieldState } = formField || {}
    const { onChange, value: formValue } = field || {}
    const { error } = fieldState
    const errorMessages = getValidationMessages(error, separator)

    return (
      <StyledWrapper $myProbatix={$myProbatix}>
        <Typography align="center" fontWeight={600}>
          {formatDateLocale(selectedDay, 'MMMM yyyy')}
        </Typography>
        <StyledWeekWrapper>
          <StyledChevronBox onClick={() => !isFetching && handleChangeDate(-1)}>
            <Icon icon="chevron-left" />
          </StyledChevronBox>
          <VirtualizeSwipeableViews
            slideRenderer={slideRenderer}
            enableMouseEvents
            onChangeIndex={(index, indexLatest) => handleChangeDate(index > indexLatest ? 1 : -1)}
          />
          <StyledChevronBox onClick={() => !isFetching && handleChangeDate(1)}>
            <Icon icon="chevron-right" />
          </StyledChevronBox>
        </StyledWeekWrapper>
        {(!availabilityTimes?.length && !isFetchingTimes) && (
          <Typography align="center" color={COLORS.BLACK} fontSize="0.675rem" fontWeight={600} margin="25px 0 0">
            <Trans k="components:AdvancedTimePicker:noAvailableSlots" />
          </Typography>
        )}
        <StyledButtonsWrapper amountButtons={timesSection?.length}>
          {timesSection?.map(({ title }, index) => (
            <Button
              align="left"
              fontSize="14px"
              fontWeight={400}
              key={title}
              label={(
                <Typography>
                  <Trans k={title} />
                </Typography>
              )}
              padding="10px"
              textColor={Number(activeTimeSection) === Number(index) ? undefined : COLORS.CADET_BLUE}
              version={Number(activeTimeSection) === Number(index) ? 'blue_light_bordered' : 'neutral_bordered'}
              capitalize
              oneColumn
              onClick={() => !isFetching && scrollToTimeSectionTitle(index)}
            />
          ))}
        </StyledButtonsWrapper>
        <StyledTimeWrapper onScroll={handleScroll}>
          {(isFetching || isFetchingTimes)
            ? <Spinner />
            : timesSection!.map((timeSection, index) => renderTimeSection(formValue, onChange)(
              timeSection as any,
              index,
            ))
          }
        </StyledTimeWrapper>
        <ErrorText fontWeight={600} margin="25px 0 0" msg={errorMessages} />
      </StyledWrapper>
    )
  }

  return (
    <Controller
      control={control}
      name={name}
      render={renderField}
    />
  )
}

export default AdvancedTimePicker
