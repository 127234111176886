import React from 'react'

import { StyledBreadcrumbItem, StyledBreadcrumbItemWrapper } from './Breadcrumb.styled'

export interface BreadcrumbItemProps {
  $isActive: boolean,
  dataTestId?: string,
  label: string
  to: string
}

const BreadcrumbItem: any = ({ $isActive, dataTestId, label, to }) => (
  <StyledBreadcrumbItemWrapper data-testid={dataTestId}>
    <StyledBreadcrumbItem $isActive={$isActive} to={to}>
      {label}
    </StyledBreadcrumbItem>
  </StyledBreadcrumbItemWrapper>
)

export default BreadcrumbItem
