import React from 'react'

import { TARGET_TYPES } from '@probatix/common/constants/css'
import { IconType } from '@probatix/common/constants/icons'

import { typeByObject } from '@probatix/common/utils/typescript'

import Icon from '@probatix/common/components/atoms/Icon/Icon'
import Spinner from '@probatix/common/components/atoms/Spinner'

import useSmallScreens from '@probatix/common/hooks/useSmallScreens'
import Grid from '../Grid'

import {
  BUTTON_SIZE,
  BUTTON_VERSION,
  StyledButton,
  StyledSpinnerWrapper,
} from './Button.styled'

// @ts-nocheck
export interface ButtonProps {
  $zIndex?: number;
  align?: string;
  bgColor?: string;
  border?: string;
  bottom?: string;
  boxShadow?: string;
  capitalize?: boolean;
  dataQa?: string;
  dataTestId?: string;
  decoration?: string;
  disabled?: boolean;
  download?: string;
  fontSize?: string;
  fontWeight?: number;
  form?: string;
  full?: boolean;
  gridGap?: string;
  height?: string;
  hoverBGColor?: string,
  hoverColor?: string;
  icon?: IconType;
  iconColor?: string;
  iconSize?: number;
  label: string | React.ReactNode;
  left?: string;
  loading?: boolean;
  margin?: string;
  mobilePadding?: string;
  onClick?: () => void;
  oneColumn?: boolean,
  padding?: string;
  position?: string;
  radius?: string;
  right?: string;
  showLoading?: boolean;
  size?: typeByObject<typeof BUTTON_SIZE>;
  submit?: boolean;
  target?: typeByObject<typeof TARGET_TYPES>;
  textColor?: string;
  to?: string;
  version?: typeByObject<typeof BUTTON_VERSION>;
  width?: string;
}

const Button: React.FC<ButtonProps> = ({
  $zIndex,
  align,
  bgColor,
  border,
  bottom,
  boxShadow,
  capitalize,
  dataQa,
  dataTestId,
  decoration,
  disabled,
  download,
  fontSize,
  fontWeight,
  form,
  full,
  gridGap,
  height,
  hoverBGColor,
  hoverColor,
  icon,
  iconColor,
  iconSize,
  label,
  left,
  loading,
  margin,
  mobilePadding,
  onClick,
  oneColumn,
  padding,
  position,
  radius,
  right,
  showLoading,
  size,
  submit,
  target,
  textColor,
  to,
  version,
  width,
}) => {
  const [$isSmallScreen] = useSmallScreens()
  const showGrid = ('full_transparent' !== version && !oneColumn) || showLoading

  return (
    <StyledButton
      $align={align}
      $bgColor={bgColor}
      $border={border}
      $bottom={bottom}
      $boxShadow={boxShadow}
      $capitalize={capitalize}
      $decoration={decoration}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $full={full}
      $gridGap={gridGap}
      $hasIcon={!!icon}
      $height={height}
      $hoverBGColor={hoverBGColor}
      $hoverColor={hoverColor}
      $iconColor={iconColor}
      $isLoading={loading}
      $isSmallScreen={$isSmallScreen}
      $left={left}
      $margin={margin}
      $mobilePadding={mobilePadding}
      $padding={padding}
      $position={position}
      $radius={radius}
      $right={right}
      $size={size}
      $textColor={textColor}
      $version={version}
      $width={width}
      $zIndex={$zIndex}
      data-qa={dataQa}
      data-testid={dataTestId}
      disabled={loading || disabled}
      download={download}
      form={form}
      label=""
      target={target}
      to={to}
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
    >
      <Grid
        gridTemplateColumns={showGrid ? '25px auto 25px' : 'auto'}
      >
        {showGrid && (
          <Grid.Item>
            {icon && <Icon color={iconColor} icon={icon} size={iconSize} />}
          </Grid.Item>
        )}
        <Grid.Item>
          {showGrid && <React.Fragment>&nbsp;</React.Fragment>}
          {label}
          {showGrid && <React.Fragment>&nbsp;</React.Fragment>}
        </Grid.Item>
        {showGrid && (
          <Grid.Item>
            {loading || showLoading && (
              <StyledSpinnerWrapper>
                <Spinner display="inline" size="small" variant="white" />
              </StyledSpinnerWrapper>
            )}
          </Grid.Item>
        )}
      </Grid>
    </StyledButton>
  )
}
export default Button
