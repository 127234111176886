import { EnterVoucherContainerProps } from '@probatix/myprobatix/src/modals/EnterVoucher/EnterVoucher.container'

import BasicModal from './BasicModal'
import BiomarkerInformation from './BiomarkerInformation'
import EnterVoucher from './EnterVoucher'
import LoginModal from './LoginModal'

export enum ModalTypes {
  BASIC_MODAL = 'BASIC_MODAL',
  BIOMARKER_DETAIL = 'BIOMARKER_DETAIL',
  ENTER_VOUCHER = 'ENTER_VOUCHER',
  LOGIN_MODAL = 'LOGIN_MODAL'
}

export const MODALS = {
  [ModalTypes.BASIC_MODAL]: BasicModal,
  [ModalTypes.ENTER_VOUCHER]: EnterVoucher,
  [ModalTypes.LOGIN_MODAL]: LoginModal,
  [ModalTypes.BIOMARKER_DETAIL]: BiomarkerInformation,

}

export interface ModalProps {
  [ModalTypes.BASIC_MODAL]: any,
  [ModalTypes.ENTER_VOUCHER]: EnterVoucherContainerProps,
  [ModalTypes.BIOMARKER_DETAIL]: any,
  [ModalTypes.LOGIN_MODAL]: any,
}
