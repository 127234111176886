import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'

interface IStyledStickyFooter {
  $isSmallScreen: boolean,
  $myProbatix: boolean
}

export const StyledStickyFooter = styled.div<IStyledStickyFooter>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${COLORS.WHITE};
  padding: 4px;
  z-index: 199;

  div {
    width: 100%;
  }
    
  ${({ $isSmallScreen, $myProbatix }) => (
    ($isSmallScreen) ? `
    bottom: ${($myProbatix ? '80px' : '0px')};  `
      : ` left: ${($myProbatix ? '115px' : '50vw')};
    padding-right: ${($myProbatix ? '65px' : 'auto')};
  `)}
`

export const StyledAbsoluteFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 199;
  
  div {
    width: 100%;
  }
`
