import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import Typography from '@probatix/common/components/atoms/Typography'
import { LANGUAGES } from '@probatix/common/translations'

import useLocaleAwareRoute from '@probatix/common/hooks/useLocaleAwareRoute'

import {
  translationsServiceActions,
  translationsServiceSelectors,
} from '@probatix/common/services/toolkit/translations'
import { StyledLanguageItem, StyledLanguageMenu } from './LanguageSwitcher.styled'

const LanguageSwitcher = ({
  checkout = false,
  paddingInlineEnd = '40px',
}: {
  checkout?: boolean,
  paddingInlineEnd?: string
}) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const translations = useSelector(() => (
    checkout ? translationsServiceSelectors.selectTranslationsData : null
  ))
  const { replaceWithLocale, replaceWithTargetLocale } = useLocaleAwareRoute()
  const navigate = useNavigate()

  const handleChangeLanguage = (language: string) => {
    if (checkout) {
      if (translations?.[language]) {
        i18n.changeLanguage(language)
        navigate(replaceWithLocale())

        return
      }
      dispatch(
        translationsServiceActions.get({
          meta: { locale: language },
          onSuccessCallback: (res) => {
            i18n.addResourceBundle(language, 'translation', res)
            i18n.changeLanguage(language)
            navigate(replaceWithLocale())
          },
        }),
      )
    } else {
      i18n.changeLanguage(language)
      navigate(replaceWithTargetLocale(language))
    }
  }

  return (
    <StyledLanguageMenu paddingInlineEnd={paddingInlineEnd}>
      {Object.keys(LANGUAGES).map((language) => (
        <StyledLanguageItem
          $active={i18n.language === language}
          key={language}
          onClick={() => handleChangeLanguage(language)}
        >
          <Typography fontSize="0.875rem" transform="uppercase">
            {language}
          </Typography>
        </StyledLanguageItem>
      ))}
    </StyledLanguageMenu>
  )
}

export default LanguageSwitcher
