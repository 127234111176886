import styled from 'styled-components'
import { Doughnut } from 'react-chartjs-2'

interface IStyledDoughnutChart {
  $zIndex?: Number,
  options?: any
}

export const StyledDoughnutChartWrapper = styled.div`
  width: 83px;
  height: 79px;
  position: relative;
`

export const StyledDoughnutChart = styled(Doughnut) <IStyledDoughnutChart>`
  position: absolute;
  padding: 5px;
`
