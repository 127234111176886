import React, { InputHTMLAttributes, FocusEventHandler, useEffect, useState } from 'react'
import { Controller, Control } from 'react-hook-form'

import { getValidationMessages } from '@probatix/common/utils/form'
import ErrorText from '@probatix/common/components/atoms/ErrorText'
import Input from '@probatix/common/components/atoms/Input'

import eyeIcon from '@probatix/common/assets/icons/eye.svg'
import invisibleEyeIcon from '@probatix/common/assets/icons/eye-invisible.svg'
import { generateAssetUrl } from './TextField.helpers'

import { StyledInputIcon } from './TextField.styled'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  autoComplete?: string,
  checkout?: boolean,
  control: Control,
  disabled?: boolean,
  errorMargin?: string,
  label: string | React.ReactNode,
  margin?: string,
  max?: string | number,
  maxWidth?: string,
  minWidth?: string,
  name: string,
  onFocus?: FocusEventHandler<HTMLInputElement>;
  placeholder?: any,
  required?: boolean,
  right?: React.ReactNode,
  separator?: string
  textTransform?: string,
  type?: string,
  variant?: string
}

const TextField: React.FC<TextFieldProps> = React.forwardRef(({
  autoComplete,
  checkout = false,
  control,
  disabled,
  errorMargin = '5px 0 7px',
  label,
  margin,
  max,
  maxWidth,
  minWidth,
  name,
  onFocus,
  required,
  right,
  separator = '.',
  textTransform = 'none',
  type = 'text',
  variant = 'text',
}, ref) => {
  const [visibleEye, setVisibleEye] = useState(false)

  useEffect(() => {
    if ('password' === variant) {
      setVisibleEye(true)
    }
  }, [])

  const renderField = (formField) => {
    const { field, fieldState } = formField
    const { onChange } = field || {}
    const { error } = fieldState

    const errorMessages = getValidationMessages(error, separator)

    if ('password' === variant) {
      return (
        <React.Fragment>
          <Input
            $hasError={null !== errorMessages}
            autoComplete={autoComplete}
            checkout={checkout}
            disabled={disabled}
            label={label}
            margin={margin}
            max={max}
            maxWidth={maxWidth}
            minWidth={minWidth}
            placeholder=" "
            ref={ref}
            required={required}
            right={(
              <StyledInputIcon
                checkout={checkout}
                icon={visibleEye ? generateAssetUrl(invisibleEyeIcon) : generateAssetUrl(eyeIcon)}
                type="button"
                onClick={() => setVisibleEye(!visibleEye)}
              />
            )}
            textTransform={textTransform}
            type={visibleEye ? 'password' : 'text'}
            onChange={onChange}
            onFocus={onFocus}
            {...field}
          />
          <ErrorText margin={errorMargin} msg={errorMessages} />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Input
          $hasError={null !== errorMessages}
          checkout={checkout}
          disabled={disabled}
          label={label}
          margin={margin}
          max={max}
          minWidth={minWidth}
          placeholder=" "
          ref={ref}
          required={required}
          right={right}
          textTransform={textTransform}
          type={visibleEye ? 'text' : type}
          onChange={onChange}
          {...field}
        />
        <ErrorText margin={errorMargin} msg={errorMessages} />
      </React.Fragment>
    )
  }

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={renderField}
    />
  )
})

export default TextField
