import format from 'date-fns/format'
import { utcToZonedTime } from 'date-fns-tz'

import { defaultTimeZone } from '@probatix/common/hooks/useFormattedDateTime'
import {
  GuestBooking,
  TestStatus,
} from '@probatix/common/services/RTKServices/index.model'

import { PatientEvent } from './index.model'

const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'X:00").replace('+', '%2B')

export const basePath = `${process.env.REACT_APP_CORE_API_ENTRYPOINT}/api-jwt/v3`
export const basePathPatients = `${process.env.REACT_APP_CORE_API_ENTRYPOINT}/api-myp/patients`
export const basePathPatientEvents = `${basePath}/patient_events`

export const testAppointmentsEndpoint = `
${basePathPatientEvents}?orderBy[appointmentTime]=asc&status[]=new&status[]=cancelled&page=`
export const testResultsEndpoint = `${basePathPatientEvents}?orderBy[testEndTime]=desc&exists[testEndTime]=true&page=`
export const timeFilter = `&appointmentTime[after]=${now}`
export const partial = '&partial=false'

export const transformPatientEventsIntoGuestBooking = (test: PatientEvent): GuestBooking => {
  const appointmentTime = test.appointmentTime ? utcToZonedTime(test.appointmentTime, defaultTimeZone) : new Date()

  return {
    ...test,
    appointmentTime,
    biomarkers: test.biomarkers ?? {},
    bookingPdf: '',
    isRefunded: test.isRefunded,
    location: {
      address: test.location?.address!,
      addressComponents: [{
        // eslint-disable-next-line camelcase
        long_name: test!.location!.longitude!,
        // eslint-disable-next-line camelcase
        short_name: test!.location!.latitude!,
        types: ['', ''],
      }],
      availableProductType: [],
      description: '',
      isDefault: false,
      isShowLocationOnFrontend: false,
      latitude: test!.location!.latitude!,
      longitude: test!.location!.longitude!,
      name: '',
      urlKey: test.probatixLocationUuid!,
      uuid: '',
      vicinity: '',
    },
    partner: test.partner,
    product: {
      addressRequired: false,
      baseProductName: test.product?.baseProductName,
      baseProductNameTrans: test.product?.baseProductNameTrans!,
      description: '',
      eligibilitySelfConfirmationType: test.product?.eligibilitySelfConfirmationType!,
      grossPrice: 0,
      healthInsuranceCompanyNumberRequired: false,
      healthInsurancePolicyNumberRequired: false,
      isEligibilityReasonsRequired: false,
      isPersonalIdRequired: false,
      isShowProductOnFrontend: false,
      minimumAge: 0,
      name: test.testType,
      phoneNumberRequired: false,
      testType: '',
      urlKey: test.probatixProductUuid!,
      uuid: test.uuid!,
      vatPerUnit: 0,
    },
    status: test.status as TestStatus,
    uuid: test.uuid!,
  }
}

export const returnPatientEventsFromResponse = (data) => {
  const totalItems = data?.totalItems

  const patientEvents = data?.['member']

  return { patientEvents, totalItems }
}

export const transformLocalTimeToBerlinTime = (date: Date): Date => {
  const appointmentTime = date ? utcToZonedTime(date, defaultTimeZone) : new Date()

  return appointmentTime
}

