import { Property } from 'csstype'

import { CursorTypes } from '@probatix/common/constants/css'

import { typeByObject } from '@probatix/common/utils/typescript'

export const VARIANT_TAGS = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SMALL: 'small',
  SPAN: 'span',
}

export const ALIGN_OPTIONS = {
  CENTER: 'center',
  JUSTIFY: 'justify',
  LEFT: 'left',
  RIGHT: 'right',
}

export interface TypographyPropsBasic {
  $firstLine?: boolean,
  $lineHeight?: string,
  $wordBreak?: Property.WordBreak,
  align?: typeByObject<typeof ALIGN_OPTIONS>,
  background?: string,
  clamp?: Number,
  color?: string,
  cursor?: CursorTypes,
  ellipsis?: boolean,
  fontFamily?: string,
  fontSize?: string,
  fontWeight?: Property.FontWeight,
  hoverColor?: string,
  hoverOpacity?: number,
  inline?: boolean,
  italic?: boolean,
  letterSpacing?: string,
  linkColorInherit?: boolean,
  margin?: string,
  maxWidth?: string,
  nowrap?: boolean,
  onClick?: () => void,
  opacity?: Property.Opacity,
  overflow?: Property.Overflow,
  padding?: string,
  prewrap?: boolean,
  textDecoration?: Property.TextDecoration,
  textShadow?: Property.TextShadow,
  transform?: Property.TextTransform,
  underline?: boolean,
  userSelect?: Property.UserSelect,
  variant?: typeByObject<typeof VARIANT_TAGS>,
  width?: string
}
