import React from 'react'

import { Property } from 'csstype'

import { ImageStyled } from './Image.styled'

export interface IImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  $objectFit?: Property.ObjectFit;
  $objectPosition?: string;
  dataQA?: string,
  full?: boolean;
  height?: number;
  loading?: "eager" | "lazy",
  margin?: Property.Margin;
  onError?: any;
  priority?: boolean;
  src: string;
  style?: any,
  width?: number;
}

const Image: React.FC<IImageProps> = ({
  $objectFit = 'contain',
  $objectPosition = 'inherit',
  dataQA,
  full,
  height = 64,
  loading = "lazy",
  margin = 'auto',
  onError,
  priority = false,
  src,
  style,
  width = 181,
}) => {
  const renderImage = (<ImageStyled
    $objectFit={$objectFit}
    $objectPosition={$objectPosition}
    alt="Image"
    data-qa={dataQA}
    full={full}
    height={height}
    loading={loading}
    margin={margin}
    priority={priority}
    src={src}
    style={style}
    width={width}
    onError={onError}
  />)

  return <picture>
    <source media="(max-width:699px)" srcSet={src} type="image/webp" />
    <source media="(max-width:640px)" srcSet={src} type="image/webp" />
    {renderImage}
  </picture>

}

export default Image
