import { Gender } from '@probatix/common/services/RTKServices/patients/index.model'

export const GENDER_OPTIONS = (separator: string = '.') => ([
  {
    label: `global${separator}gender${separator}male`,
    value: Gender.male,
  },
  {
    label: `global${separator}gender${separator}female`,
    value: Gender.female,
  },
])

export const STATE_KEY: string = 'users'
