import React from 'react'

import { Property } from 'csstype'
import Image from '../Image'

interface LogoProps {
  height?: number
  margin?: Property.Margin;
  src: string
  width?: number
}

const Logo: React.FC<LogoProps> = (props: LogoProps) => <Image {...props} />

export default Logo
